export function scrollToForm() {
    const buttons = document.querySelectorAll('.js-scroll-to-form');
    const buttonForm = document.querySelector('.button_form-call');
    const sectionForm = document.querySelector('#section6');

    function isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    document.addEventListener('scroll', () => {
        if (isElementInViewport(sectionForm)) {
            buttonForm.classList.add('is-visible');
        } else {
            buttonForm.classList.remove('is-visible');
        }
    });

    for (let btn of buttons) {
        btn.addEventListener('click', (e) => {
            e.preventDefault();

            window.scrollTo(0, 100000);
        })
    }
}