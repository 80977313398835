import { gsap } from "gsap";
import { ScrollTrigger, } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);

const minWidth = 992;
const minHeight = 600;

if (window.innerWidth < minWidth || window.innerHeight < minHeight) {
    console.log('Запусе ScrollSmoother не выполняется: разрешение экрана слишком низкое.');
} else {
    ScrollSmoother.create({
        smooth: 2,
        speed: 0.2,
        effects: true,
    })
}

let gsapInit = false;

function scrollToSection(target, index) {
    const targetElement = document.querySelector(target);

    window.scrollTo(0, 0);

    if (window.innerWidth < minWidth || window.innerHeight < minHeight) {
        window.scrollTo(0, targetElement.offsetTop);
    } else {
        if (index <= 1) {
            window.scrollTo( 0, targetElement.offsetHeight * 2 * index);
        } else if (index <= 2) {
            window.scrollTo(0, targetElement.offsetHeight * 2.5 * index);
        }  else if (index <= 3) {
            window.scrollTo(0, targetElement.offsetHeight * 3.3 * index);
        } else {
            window.scrollTo(0, targetElement.offsetHeight * 3.5 * index);
        }
    }
}

export function scrollAnimation() {
    const scrollLinks = document.querySelectorAll('.js-link-navigation');
    const scrollSidebarLinks = document.querySelectorAll('.js-menu-link');
    const scrollTo = (links, callback) => {
        links.forEach((link, index) => {
            const target = link.querySelector('span')?.getAttribute('data-target');

            link.addEventListener('click', (event) => {
                event.preventDefault();
                scrollToSection(`#${target}`, index);


                if (callback !== undefined) {
                    callback()
                }
            });
        });
    }
    const hideSidebar = () => {
        let sidebar = document.querySelector('.js-menu-sidebar');

        sidebar.classList.remove('active');
    }

    scrollTo(scrollLinks);
    scrollTo(scrollSidebarLinks, hideSidebar);
}

export function gsapAnimation() {
    function activeLink(indexLink) {
        let links = document.querySelectorAll('.js-link-navigation');
        let linksSidebar = document.querySelectorAll('.js-menu-link');
        const isActive = (listLinks) => {
            for (let link of listLinks) {
                link.classList.remove('active');
            }
            listLinks[indexLink].classList.add('active');
        }

        isActive(links);
        isActive(linksSidebar);
    }
    let animations = [];
    let animateSection, animateSection1, animateSection2, animateSection3, animateSection4, animateSection4_1, animateSection6;

    if (window.innerWidth < minWidth || window.innerHeight < minHeight) {
        console.log('Запусе анимаций не выполняется: разрешение экрана слишком низкое.');

        return;
    }

    if (!gsapInit) {

        gsapInit = true;

        const titles = document.querySelectorAll('.js-animation-section-1-title');

        titles.forEach(title => {
            const letters = title.textContent.split('');
            title.innerHTML = letters.map(letter => `<span>${letter}</span>`).join('');
        });

        gsap.fromTo('.js-animation-section-1-title span',
            {
                y: '100%',
                opacity: 0
            },
            {
                y: '0%',
                opacity: 1,
                duration: 0.5,
                stagger: {
                    amount: 1,
                    from: "random"
                },
                ease: 'power3.out'
            }
        );

        gsap.fromTo('.js-animation-section-1-text span',
            {
                y: '100%',
                opacity: 0
            },
            {
                y: '0%',
                opacity: 1,
                duration: 0.5,
                stagger: {
                    amount: 1,
                    from: "start"
                },
                ease: 'power3.out'
            }
        );

        animateSection = gsap.timeline({
            scrollTrigger: {
                trigger: "#container-pin1",
                start: "top top",
                end: "bottom top",
                invalidateOnRefresh: true,
                scrub: true,
                pin: true,
                pinSpacing: true,
                markers: false,
            }
        });

        animateSection1 = gsap.timeline({
            scrollTrigger: {
                trigger: "#section1",
                start: "top top",
                end: "top+=10 top",
                invalidateOnRefresh: true,
                scrub: true,
                markers: false,
                onEnter: () => activeLink(0),
                onEnterBack: () => activeLink(0),
            }
        });
        animateSection2 = gsap.timeline({
            scrollTrigger: {
                trigger: "#section2",
                start: "center+=10 center",
                end: "center+=400 center",
                scrub: true,
                markers: false,
                invalidateOnRefresh: true,
                onEnter: () => activeLink(1),
                onEnterBack: () => activeLink(1)
            }
        });

        animateSection3 = gsap.timeline({
            scrollTrigger: {
                trigger: "#section3",
                start: "top+=50% top",
                scrub: true,
                markers: false,
                invalidateOnRefresh: true,
                onEnter: () => activeLink(2),
                onEnterBack: () => activeLink(2),
            }
        });

        animateSection4 = gsap.timeline({
            scrollTrigger: {
                trigger: "#container-pin2",
                start: "top center",
                end: "center center",
                scrub: true,
                markers: false,
                invalidateOnRefresh: true,
                onEnter: () => activeLink(3),
                onEnterBack: () => activeLink(3),
            }
        });

        animateSection4_1 = gsap.timeline({
            scrollTrigger: {
                trigger: "#container-pin2",
                start: "top top",
                end: "bottom top",
                scrub: true,
                pin: true,
                pinSpacing: true,
                markers: false,
                invalidateOnRefresh: true,
                onEnter: () => activeLink(4),
                onEnterBack: () => activeLink(4),
            }
        });

        animateSection6 = gsap.timeline({
            scrollTrigger: {
                start: "top+=300 center",
                trigger: "#section6",
                markers: false,
            }
        });

        window.scrollTo(0, 0);

        animations.push(animateSection, animateSection1, animateSection2, animateSection3, animateSection4, animateSection4_1, animateSection6);
    }

    animateSection
        .to("#section1", { x: "-100%" }, 0)
        .to("#section2", { x: "0%" }, 0)
        .to(".section__corner", { scale: 1, transformOrigin: "bottom right" }, 1)
        .to("#section3", { clipPath: "inset(0% 0% 0% 0%)" }, 1)
        .to(".section__corner", { y: "-100%", x: "-50%" }, 2);

    animateSection2
        .to('.js-animation-section-2-title', { x: "0%", opacity: 1 }, 0)
        .to('.js-animation-section-2-img', { x: "0%", opacity: 1 }, 0)
        .to('.js-animation-section-2-text', { y: "0%", opacity: 1 }, 0);

    animateSection3
        .to(".js-section-3-animation", { y: "0" }, 0)
        .to('.js-animation-section-3-title', { x: "0%", opacity: 1 }, 1)
        .to('.js-animate-expertise-item1', { y: "0%", opacity: 1 }, 1)
        .to('.js-animate-expertise-item2', { y: "0%", opacity: 1 }, 2)
        .to('.js-animate-expertise-item3', { y: "0%", opacity: 1 }, 3)
        .to('.js-animate-expertise-item4', { y: "0%", opacity: 1 }, 4);

    animateSection4
        .to('.js-animation-section-4-title', {
            y: "0%",
            opacity: 1,
            duration: 1,
            ease: "power2.inOut",
        }, 0)
        .to('.js-animation-section-4-item1', {
            x: "0%",
            opacity: 1,
            duration: 2,
            ease: "power2.inOut",
        }, 0)
        .to('.js-animation-section-4-item2', {
            x: "0%",
            opacity: 1,
            duration: 2,
            ease: "power2.inOut",
        }, 1)
        .to('.js-animation-section-4-item3', {
            x: "0%",
            opacity: 1,
            duration: 2,
            ease: "power2.inOut",
        }, 2);

    animateSection4_1
        .to("#section4", {
            x: "-100%",
            duration: 2,
        }, 0)
        .to("#section5", {
            x: "0%",
            duration: 2,
        }, 0);

    animateSection4_1
        .to('.js-animation-section-5-title', {
            y: "0%",
            opacity: 1,
            duration: 1,
            ease: "power2.inOut",
        }, 0)
        .to('.js-animation-section-5-item1', {
            x: "0%",
            opacity: 1,
            duration: 2,
            ease: "power2.inOut",
        }, 0)
        .to('.js-animation-section-5-item2', {
            x: "0%",
            opacity: 1,
            duration: 2,
            ease: "power2.inOut",
        }, 1)
        .to('.js-animation-section-5-item3', {
            x: "0%",
            opacity: 1,
            duration: 2,
            ease: "power2.inOut",
        }, 2)

    animateSection6
        .to('.js-section-6-text1', {
            y: "0%",
            opacity: 1,
            duration: 1,
            ease: "power2.inOut",
        }, 0)
        .to('.js-section-6-text2', {
            y: "0%",
            opacity: 1,
            duration: 1,
            ease: "power2.inOut",
        }, 1)
        .to('.js-section-6-text3', {
            y: "0%",
            opacity: 1,
            duration: 1,
            ease: "power2.inOut",
        }, 2);

    setTimeout(() => ScrollTrigger.refresh(), 500);

    function killAnimations() {
        animations.forEach(animation => animation.kill());

        const allTriggers = ScrollTrigger.getAll();
        allTriggers.forEach(trigger => trigger.kill(true));

        ScrollTrigger.killAll();
    }

    function restartAnimations() {
        killAnimations();
        gsapAnimation();
    }

    return { killAnimations, restartAnimations };
}
