export function animateExpertise() {
    const navItems = document.querySelectorAll('.js-animate-expertise-item');
    const images = document.querySelectorAll('.js-animate-expertise-image');

    navItems.forEach((item, index) => {
        item.addEventListener('mouseover', function() {
            images.forEach((image, imgIndex) => {
                if (index === imgIndex) {
                    image.classList.add('active');
                } else {
                    image.classList.remove('active');
                }
            });
        });
    });
}