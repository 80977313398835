import {gsapAnimation, scrollAnimation} from "@/js/gsapAnimation.js";
import {animateExpertise} from "@/js/animateExpertise.js";
export function loaderImages(callback) {
    document.addEventListener("DOMContentLoaded", function () {
        const overlay = document.createElement("div");
        document.body.appendChild(overlay);
        overlay.classList.add("overlay");

        const progressElement = document.createElement("div");
        progressElement.style.fontSize = "24px";
        progressElement.style.color = "#000";
        progressElement.textContent = "Loading: 0%";
        overlay.appendChild(progressElement);

        const images = document.querySelectorAll("img");
        const totalImages = images.length;
        let loadedImages = 0;

        function updateProgress() {
            const percent = Math.round((loadedImages / totalImages) * 100);
            progressElement.textContent = `Загрузка: ${percent}%`;

            if (loadedImages === totalImages) {
                progressElement.textContent = "Спасибо за ожидание!"
                setTimeout(() => overlay.remove(), 500)

                if (callback !== undefined) {
                    callback();
                }
            }
        }

        images.forEach(img => {
            if (img.complete) {
                loadedImages++;
                updateProgress();
            } else {
                img.addEventListener("load", () => {
                    loadedImages++;
                    updateProgress();
                });
                img.addEventListener("error", () => {
                    loadedImages++;
                    updateProgress();
                });
            }
        });
    });
}
